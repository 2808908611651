import request from './Request';
const { ipcRenderer } = window.require('electron');

export const initialize = () => {
  ipcRenderer.on('GET_FIREBASE_TOKEN', async () => {
    const token = await request.getIdToken();
    ipcRenderer.send('GET_FIREBASE_TOKEN', { token });
  });
  ipcRenderer.on('GET_LOG', async (event, message) => {
    console.log(`Message from electron: ${message}`);
  });
};
