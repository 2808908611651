import { onValue, off, remove, child, update, runTransaction, get } from 'firebase/database';
import AppConfig from '../../Config/AppConfig';
import axios from 'axios';

const orderListener = ({ orderRef, failedOrderRef }) => async snapshot => {
  const orders = snapshot.val();
  console.log('Order Listener Called');
  if (orders && typeof orders === 'object' && Object.keys(orders)) {
    removeListener({ ref: orderRef });
    try {
      await processOrders({ ordersObj: orders, orderRef, failedOrderRef });
    } finally {
      attachListener({ orderRef, failedOrderRef });
    }
  }
};

const processOrder = async ({ order, orderId, orderRef, failedOrderRef }) => {
  try {
    if (order.retryCount && order.retryCount > AppConfig.maxRetryCount) {
      await remove(child(orderRef, orderId));
      await update(child(failedOrderRef, orderId), { ...order, retryCount: 0 });
      throw new Error('Max Retry Count Exceeded');
    }
    await runTransaction(child(orderRef, `${orderId}/retryCount`), ct => {
      if (!ct) {
        ct = 0;
      }
      return ct + 1;
    });
    await axios.post(`${AppConfig.localApiEndpoint}/v1/inventory/tally`, order);
    console.log('Voucher created for order: ', orderId);
    await remove(child(orderRef, orderId));
  } catch (err) {
    console.error(err);
  }
};

export const processOrders = async ({ ordersObj, orderRef, failedOrderRef }) => {
  for (const orderId of Object.keys(ordersObj)) {
    const order = ordersObj[orderId];
    await processOrder({ order, orderId, orderRef, failedOrderRef });
  }
};

export const attachListener = ({ orderRef, failedOrderRef }) => {
  console.log('AttachListener Called');
  onValue(orderRef, orderListener({ orderRef, failedOrderRef }));
  get(orderRef)
    .then(val => console.log(`GET successful: ${val}`))
    .catch(err => console.log(err));
};

export const removeListener = ({ ref }) => {
  off(ref, 'value');
};
