const { ipcRenderer } = window.require('electron');

export const generateExcel = (setDownloadingExcel, setDownloadedExcelMessage) => {
  ipcRenderer.send('GENERATE_EXCEL');
  ipcRenderer.on('GENERATE_EXCEL', async (event, data) => {
    if (data && data === 'FAILED_TO_DOWNLOAD_EXCEL') {
      setDownloadingExcel(false);
      setDownloadedExcelMessage('Failed to download!');
    }
    if (data) {
      setDownloadingExcel(false);
      setDownloadedExcelMessage(data);
    }
  });
};
